body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.site-container {
  min-height: 100vh;
}
main {
  flex: 1;
}
.btn-primary,
.btn-outline-primary {
  background-color: #f0c040;
  color: #000000;
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rating span {
  color: #ffc000;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}
.product img {
  width: 100%;
  max-width: 400px;
}
.product-info {
  padding: 1rem;
}
.img-large {
  max-width: 100%;
}
.img-thumbnail {
  height: 80px;
}
.small-container {
  max-width: 600px;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}

/* sidebar */
.navbar-brand {
  font-weight: bold;
}
.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}


.site-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}
.active-nav {
  left: 0;
}
.text-bold {
  font-weight: bold;
}

/* Map */

.full-box {
  height: 100vh;
  max-width: initial;
}
.full-box header,
.full-box footer {
  display: none;
}
.full-box .container {
  height: 100vh;
  max-width: initial;
  margin: 0;
  padding: 0;
}

.full-box .mt-3 {
  margin-top: 0 !important;
}

.small-container.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgb(0, 0, 0)
}

.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 3rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
button.thumbnail {
  padding: 0;
}

.table-responsive {
  overflow-x: auto;
  padding: 1rem;
}

.p-3 {
  padding: 1rem !important;
}

.navbar {
  z-index: 100;
}

body {
  padding-top: 70px; /* Height of the fixed navbar */
}

.content {
  margin-top: 70px; /* Height of the fixed navbar */
  padding-left: 220px; /* Width of the sidebar plus some extra space */
}

.home-button {
  margin-right: 1rem;
  margin-left: -1rem;
  display: flex;
  align-items: center;
}

.approve-button {
  background-color: green;
  color: white;
}

.delete-button {
  background-color: red;
  color: white;
}

.view-button {
  background-color: rgb(218, 121, 42);
  color: white;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .card-img-top {
    width: 100%;
  }
}

.home-link {
  display: block;
  margin-top: 20px;
  text-align: center;
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.home-link:hover {
  text-decoration: underline;
}

.products img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  min-height: 500px;
  min-width: 500px;
  max-width: 100%;

}

.confirmation-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.confirmation-heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.confirmation-text {
  font-size: 20px;
  margin-bottom: 10px;
}

.confirmation-address {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.site-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Use 100vh to make sure the container fills the viewport height */
}

.side-navbar {
  position: fixed;
  top: 60px; /* Adjust this value based on your navbar's height */
  bottom: 0;
  width: 250px; /* Set a width for the sidebar */
  background-color: #1a2d3f; /* Set a background color for the sidebar */
  z-index: 1; /* Make sure the sidebar appears on top of other content */
}

/* Example CSS for mobile displays */
@media (max-width: 576px) {
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.expanded-view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.active-cont {
  margin-left: 250px; /* Adjust this value based on the width of the sidebar */
}

.products-container img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  max-width: 100%;
}
